import { EnPaymentSubscriptionType } from '@prisma/client'
import {
  BRONZE_SUBSCRIPTION_ONE,
  BRONZE_SUBSCRIPTION_RECCURENT,
  GOLD_SUBSCRIPTION_ONE,
  GOLD_SUBSCRIPTION_RECCURENT,
  SILVER_SUBSCRIPTION_ONE,
  SILVER_SUBSCRIPTION_RECCURENT,
} from 'src/config'

export const getSubscriptionPrice = (
  subscriptionType: EnPaymentSubscriptionType,
  isReccurent: boolean,
) => {
  switch (subscriptionType) {
    case EnPaymentSubscriptionType.BRONZE: {
      return isReccurent
        ? BRONZE_SUBSCRIPTION_RECCURENT
        : BRONZE_SUBSCRIPTION_ONE
    }

    case EnPaymentSubscriptionType.SILVER: {
      return isReccurent
        ? SILVER_SUBSCRIPTION_RECCURENT
        : SILVER_SUBSCRIPTION_ONE
    }

    case EnPaymentSubscriptionType.GOLD: {
      return isReccurent ? GOLD_SUBSCRIPTION_RECCURENT : GOLD_SUBSCRIPTION_ONE
    }

    default: {
      return 0
    }
  }
}

export const getStartSubscriptionPrice = (type: EnPaymentSubscriptionType) => {
  switch (type) {
    case EnPaymentSubscriptionType.BRONZE: {
      return BRONZE_SUBSCRIPTION_ONE
    }

    case EnPaymentSubscriptionType.SILVER: {
      return SILVER_SUBSCRIPTION_ONE
    }

    case EnPaymentSubscriptionType.GOLD: {
      return GOLD_SUBSCRIPTION_ONE
    }
  }
}

export const getMonthPeriod = (
  subscription: EnPaymentSubscriptionType | null,
) => {
  switch (subscription) {
    case EnPaymentSubscriptionType.BRONZE: {
      return 1
    }

    case EnPaymentSubscriptionType.SILVER: {
      return 3
    }

    case EnPaymentSubscriptionType.GOLD: {
      return 12
    }

    default: {
      return 0
    }
  }
}

export const getDepositBonus = (sum: number) => {
  if (sum < 250) return 0

  if (sum < 500) return 15

  if (sum < 750) return 40

  if (sum < 1000) return 65

  return 100
}
