import clsx from 'clsx'
import { ReactNode } from 'react'
import { Control, Controller, Path } from 'react-hook-form'
import { Error } from '../Error'
import { Hint } from '../Hint'
import { Label } from '../Label'

export const Input = <T extends Record<string, any>>(props: {
  control: Control<T, any>
  name: Path<T>
  title?: string
  type?: 'number' | 'text'
  placeholder?: string
  hint?: string | ReactNode
  isDisabled?: boolean
  isDisableErrors?: boolean
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, formState: { errors, touchedFields } }) => {
        const isError = errors[props.name] !== undefined
        const isSuccess = isError ? false : touchedFields[props.name]
        const error = errors[props.name]
          ? String(errors[props.name]?.message)
          : ''

        return (
          <div className="grid grid-flow-row">
            {props.title && (
              <Label
                className={clsx('', {
                  '!text-success': isSuccess,
                  '!text-danger': isError,
                })}
                name={props.name}
                title={props.title}
              />
            )}

            <input
              type={props.type || 'text'}
              id={props.name}
              {...(props.type === 'number' ? { min: 0 } : {})}
              placeholder={props.placeholder || props.title}
              className={clsx('input', {
                '!border-danger !ring-danger':
                  isError && !props.isDisableErrors,
                '!border-success !ring-success':
                  isSuccess && !props.isDisableErrors,
                'opacity-50 !bg-gray-300 dark:!bg-form': props.isDisabled,
              })}
              {...field}
              {...(props.isDisabled ? { disabled: true } : {})}
            />

            {!props.isDisableErrors && (
              <div className="h-5 mt-0.5 grid">
                {error && <Error className="text-xs" message={error} />}
                {!error && <Hint message={props.hint} />}
              </div>
            )}

            {!error}
          </div>
        )
      }}
    />
  )
}
